import React from "react";
import { graphql } from "gatsby";
import queryString from "query-string";
import { get } from "lodash";

import Help from "../content/help";

const HelpPage = ({ data }) => {
	const faqs = convertNodes(get(data, "allContentfulPage"));
	const videos = convertNodes(get(data, "allContentfulHelpVideo")).map(
		video => ({
			...video,
			video: {
				id: queryString.parse(video.videoUrl.split("?")[1]).v,
				title: video.title
			}
		})
	);

	const nonHiddenFaqs = faqs.filter(
		f =>
			f.title
				.trim()
				.toLowerCase()
				.endsWith("-hidden") === false
	);

	return <Help {...{ faqs: nonHiddenFaqs, videos }} />;
};

function convertNodes(all) {
	const nodes = get(all, "edges");
	if (!nodes) return [];
	return nodes.map(n => n.node);
}

export default HelpPage;

export const pageQuery = graphql`
	query HelpQuery {
		allContentfulPage(
			filter: { pageType: { eq: "FAQ" } }
			sort: { fields: [title], order: ASC }
		) {
			edges {
				node {
					slug
					title
				}
			}
		}

		allContentfulHelpVideo {
			edges {
				node {
					id
					title
					videoUrl
				}
			}
		}
	}
`;
