import React from "react";
import BaseAuctionIcon, { SALE_TYPES } from "../../info-block/icon";
import { ReactComponent as SaveIcon } from "../../icons/save.svg";
import { COLOR_SAVE } from "../../colors";

const FaqIcon = ({ faqTitle = "" }) => {
	const isAdj =
		faqTitle.toLowerCase().includes("adjudicat") ||
		faqTitle.toLowerCase().includes("propert");

	const isBulkBidding = faqTitle.toLowerCase().includes("bidding");

	const iconStyle = { display: "inline-block", fontSize: 16 };

	return isBulkBidding ? (
		<>
			<SaveIcon style={{ fill: COLOR_SAVE, ...iconStyle }} />
		</>
	) : (
		<BaseAuctionIcon
			auctionType={isAdj ? SALE_TYPES.ADJ : SALE_TYPES.TAX}
			style={iconStyle}
		/>
	);
};

export default FaqIcon;
