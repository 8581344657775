import React from "react";
import { Video } from "@civicsource/ui";
import SectionHeader from "../../landing/section-header";

const Videos = ({ videos }) => {
	return (
		<div>
			<SectionHeader>Videos</SectionHeader>
			<Video.Gallery videos={videos} linkName="FAQ" />
		</div>
	);
};

export default Videos;
