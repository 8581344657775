import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import SectionHeader from "../../landing/section-header";

import FaqIcon from "./faq-icon";

const FaqHelpLinks = ({ faqs }) => {
	return (
		<div>
			<SectionHeader>Frequently Asked Questions</SectionHeader>
			<Row>
				<Col md={12}>
					{faqs.map(({ slug, title }) => (
						<div key={slug}>
							<Link className="btn-link" to={`/help/${slug}`}>
								<FaqIcon faqTitle={title} /> {title}
							</Link>
						</div>
					))}
				</Col>
			</Row>
		</div>
	);
};

export default FaqHelpLinks;
