import React from "react";
import useSheet from "react-jss";
import { bootstrap } from "toetag";
import { oneLineTrim } from "common-tags";

const SECTION_HEADER_DECORATION_COLOR = "#e7e7e7";

const generateMidLineBg = (
	ruleColor = SECTION_HEADER_DECORATION_COLOR
) => oneLineTrim`
	url("
	data:image/svg+xml,
	${encodeURIComponent(`
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="1" shape-rendering="crispEdges">
		<path fill="${ruleColor}" d="M0 0h 24v 1h-24z"/>
	</svg>
	`)}
	")
`;

const landingHeaderBg = generateMidLineBg();

const styles = {
	container: {
		margin: [8, 0],
		lineHeight: 1,
		display: "block",
		composes: "clearfix",
		borderStyle: "solid",
		borderColor: SECTION_HEADER_DECORATION_COLOR,
		borderWidth: "0 1px",
		backgroundImage: landingHeaderBg,
		backgroundRepeat: "repeat-x",
		backgroundPosition: "0 50%",
		fontWeight: 600,
		textTransform: "uppercase",
		"&, &:hover, &:focus": {
			color: "#4d4d4d"
		}
	},
	inner: {
		backgroundColor: "#fff",
		borderStyle: "solid",
		borderWidth: "0 1px",
		borderColor: SECTION_HEADER_DECORATION_COLOR,
		padding: [0, bootstrap.paddingBaseHorizontal]
	},
	content: {
		composes: ["$inner", "pull-left"],
		borderLeft: 0,
		paddingLeft: 0,
		marginLeft: -1
	},
	controls: {
		composes: ["$inner", "pull-right"],
		borderRight: 0,
		paddingRight: 0,
		margin: [-3, -1, -3, 0]
	}
};

const LandingSectionHeader = ({
	children,
	controls,
	classes,
	componentClass: Container = "h5",
	...props
}) => {
	return (
		<Container className={classes.container} {...props}>
			<div className={classes.content}>{children}</div>
			<div className={classes.controls}>{controls}</div>
		</Container>
	);
};

export default useSheet(styles)(LandingSectionHeader);
