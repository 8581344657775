import React from "react";
import { Grid, Row, Col, Panel } from "react-bootstrap";

import FaqLinks from "./faqs";
import Videos from "./videos";

const HelpPage = ({ faqs, videos }) => {
	return (
		<Grid>
			<Row>
				<Col>
					<Panel>
						<Row>
							<Grid>
								<FaqLinks {...{ faqs }} />
								<br />

								<Videos {...{ videos }} />
							</Grid>
						</Row>
					</Panel>
				</Col>
			</Row>
		</Grid>
	);
};

export default HelpPage;
